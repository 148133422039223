<template>
  <div
    v-outside
    class="flex flex-col w-full"
    :class="[
      fullWidth === 'true' ? '' : width ? width : 'md:max-w-[396px]',
      label ? (customGap ? customGap : 'gap-3') : '',
    ]"
  >
    <div class="flex items-center gap-3">
      <p
        class=""
        :class="
          (((customLeading ? 'leading-[21px]' : 'leading-none',
          dark && 'text-white'),
          labelLeading ? labelLeading : ''),
          labelClasses
            ? labelClasses
            : darkLabel
            ? 'font-semibold text-sm/none text-neutral-800  cursor-default'
            : 'font-semibold text-sm/none text-neutral-800 dark:text-white cursor-default')
        "
      >
        {{ label }}
      </p>
      <svg
        v-if="toolTipText"
        class="text-neutral-800 dark:text-neutral-200"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5625 6.5625L6.5867 6.5504C6.92102 6.38324 7.29745 6.6852 7.2068 7.04782L6.7932 8.70218C6.70255 9.0648 7.07898 9.36676 7.4133 9.1996L7.4375 9.1875M12.25 7C12.25 9.89949 9.89949 12.25 7 12.25C4.10051 12.25 1.75 9.89949 1.75 7C1.75 4.10051 4.10051 1.75 7 1.75C9.89949 1.75 12.25 4.10051 12.25 7ZM7 4.8125H7.00438V4.81688H7V4.8125Z"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div v-if="type != 'password'">
      <input
        required
        :type="type === 'password' ? passwordVisibility : type"
        :placeholder="placeHolder"
        :value="modelValue"
        :dusk="dusk"
        :min="minValue && minValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @keyup="
          (e) => {
            handleCharacterLimit(e), (AiTool.menu = false);
          }
        "
        class="outline-none text-sm text-neutral-600 placeholder:text-neutral-400 bg-white border border-neutral-300 bg-transparent hover:border-neutral-400 w-full"
        autocomplete="off"
        :disabled="disabled"
        :class="[
          disabled
            ? 'cursor-not-allowed bg-zinc-100 text-zinc-400'
            : darkInput
            ? ''
            : 'bg-white dark:bg-neutral-800 hover:border-neutral-400 text-neutral-600 dark:text-white',
          borderRadius ? borderRadius : ' rounded-md',
          moreClass ? moreClass : ' px-4 py-3',
          darkInput
            ? ''
            : 'dark:placeholder:text-neutral-400 dark:bg-neutral-800 dark:text-neutral-400 dark:border-neutral-700',
          error
            ? 'border-red-600 focus:border-red-600 hover:border-red-600'
            : disabled
            ? 'focus:border-neutral-400'
            : 'focus:border-blue-600',
          noTransition ? '' : 'transition-all ease-in-out duration-150',
          truncate ? 'truncate pr-10' : '',
          capitalize && 'capitalize',
        ]"
        :id="id"
        :name="name"
      />

      <!-- ///////////////***AI Part***///////////////////// -->

      <div
        v-if="showAssistance"
        @click.stop="(AiTool.menu = !AiTool.menu), (AiTool.promptModal = false)"
        class="absolute bottom-2.5 right-3 cursor-pointer text-neutral-800 dark:text-neutral-200"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" class="holder_f19n375c">
          <path
            d="M14.256,7.346C15.403,7.699 16.301,8.597 16.654,9.744C16.759,10.085 17.241,10.085 17.346,9.744C17.699,8.597 18.597,7.699 19.744,7.346C20.085,7.241 20.085,6.759 19.744,6.654C18.597,6.301 17.699,5.403 17.346,4.256C17.241,3.915 16.759,3.915 16.654,4.256C16.301,5.403 15.403,6.301 14.256,6.654C13.915,6.759 13.915,7.241 14.256,7.346ZM17,5.339C17.392,6.034 17.967,6.608 18.661,7C17.967,7.392 17.392,7.966 17,8.661C16.608,7.967 16.034,7.392 15.339,7C16.034,6.608 16.608,6.034 17,5.339Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0"
          ></path>
          <path
            d="M6.426,13.577C8.338,14.165 9.835,15.662 10.423,17.574C10.598,18.142 11.402,18.142 11.577,17.574C12.165,15.662 13.662,14.165 15.574,13.577C16.142,13.402 16.142,12.598 15.574,12.423C13.662,11.835 12.165,10.338 11.577,8.426C11.402,7.858 10.598,7.858 10.423,8.426C9.835,10.338 8.338,11.835 6.426,12.423C5.858,12.598 5.858,13.402 6.426,13.577ZM11,9.69C11.693,11.138 12.862,12.307 14.31,13C12.862,13.693 11.693,14.862 11,16.31C10.307,14.862 9.138,13.693 7.69,13C9.138,12.307 10.307,11.138 11,9.69Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0"
          ></path>
        </svg>
        <!-- AI -->
      </div>
      <div
        v-if="AiTool.menu && showAssistance"
        class="absolute py-1 z-10 right-2.5 w-[100px] bg-white dark:bg-neutral-800 rounded-md border dark:border-neutral-700 mt-1 min-w-fit block select-none"
      >
        <p
          v-if="modelValue"
          v-for="item in AiTool.editOPtions"
          @click.stop="
            handleAIEditor(item), (selectedOption = item), (AiTool.menu = false)
          "
          class="text-sm text-neutral-800 dark:text-neutral-200 cursor-pointer hover:bg-blue-50 dark:hover:bg-blue-400/10 hover:text-blue-600 dark:hover:text-blue-400 py-1 px-3"
        >
          {{ item }}
        </p>
        <p
          @click.stop="(AiTool.menu = false), (AiTool.promptModal = true)"
          v-else
          class="text-sm text-neutral-800 dark:text-neutral-200 cursor-pointer hover:bg-blue-50 dark:hover:bg-blue-400/10 hover:text-blue-600 dark:hover:text-blue-400 py-1 px-3"
        >
          Generate with AI
        </p>
      </div>
      <div
        v-if="AiTool.promptModal && showAssistance"
        class="absolute w-full bg-white dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700 rounded-md shadow z-20 mt-1"
      >
        <div
          v-if="AiTool.aiResponse.length > 0"
          class="flex items-center justify-between px-5 py-3 border-b border-neutral-200 dark:border-neutral-700 text-neutral-800 dark:text-neutral-200"
        >
          <p>
            {{ modelValue ? selectedOption : "Generate with AI" }}
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            @click="handlePromptClose()"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 shrink-0 cursor-pointer"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div
          v-if="AiTool.aiResponse.length > 0"
          class="px-5 py-3 flex flex-col gap-5 max-h-[300px] overflow-auto content-scroll"
        >
          <div
            v-for="(item, index) in AiTool.aiResponse"
            class="text-xs text-neutral-800 dark:text-neutral-200 flex flex-col gap-3"
          >
            <div
              v-if="item.prompt.type == 'human'"
              class="flex items-center gap-2"
            >
              <img src="/icons/aconversion_icon.png" />
              <p class="text-sm text-neutral-800 dark:text-neutral-200">
                {{ item.prompt.message }}
              </p>
            </div>
            <div v-if="item.aiResponse.type == 'bot'" class="flex gap-2">
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                class="-ml-1 shrink-0 text-neutral-800 dark:text-neutral-200"
              >
                <path
                  d="M14.256,7.346C15.403,7.699 16.301,8.597 16.654,9.744C16.759,10.085 17.241,10.085 17.346,9.744C17.699,8.597 18.597,7.699 19.744,7.346C20.085,7.241 20.085,6.759 19.744,6.654C18.597,6.301 17.699,5.403 17.346,4.256C17.241,3.915 16.759,3.915 16.654,4.256C16.301,5.403 15.403,6.301 14.256,6.654C13.915,6.759 13.915,7.241 14.256,7.346ZM17,5.339C17.392,6.034 17.967,6.608 18.661,7C17.967,7.392 17.392,7.966 17,8.661C16.608,7.967 16.034,7.392 15.339,7C16.034,6.608 16.608,6.034 17,5.339Z"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="0"
                ></path>
                <path
                  d="M6.426,13.577C8.338,14.165 9.835,15.662 10.423,17.574C10.598,18.142 11.402,18.142 11.577,17.574C12.165,15.662 13.662,14.165 15.574,13.577C16.142,13.402 16.142,12.598 15.574,12.423C13.662,11.835 12.165,10.338 11.577,8.426C11.402,7.858 10.598,7.858 10.423,8.426C9.835,10.338 8.338,11.835 6.426,12.423C5.858,12.598 5.858,13.402 6.426,13.577ZM11,9.69C11.693,11.138 12.862,12.307 14.31,13C12.862,13.693 11.693,14.862 11,16.31C10.307,14.862 9.138,13.693 7.69,13C9.138,12.307 10.307,11.138 11,9.69Z"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="0"
                ></path>
              </svg>
              <div class="flex flex-col gap-4 mt-1">
                <p class="text-sm text-neutral-800 dark:text-neutral-200">
                  {{ item.aiResponse.message }}
                </p>
                <div class="flex items-center gap-2">
                  <button
                    @click.stop="
                      (modelValue = item.aiResponse.message),
                        $emit('update:modelValue', modelValue)
                    "
                    class="text-xs border border-neutral-200 dark:border-neutral-700 py-0.5 px-2 rounded-md text-neutral-800 dark:text-neutral-200"
                  >
                    Replace
                  </button>
                  <button
                    @click.stop="
                      (modelValue += item.aiResponse.message),
                        $emit('update:modelValue', modelValue)
                    "
                    class="text-xs border border-neutral-200 dark:border-neutral-700 py-0.5 px-2 rounded-md text-neutral-800 dark:text-neutral-200"
                  >
                    Insert
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative px-5 py-3 flex flex-col gap-4">
          <div
            v-if="
              selectedOption == 'Translate' &&
              modelValue &&
              AiTool.aiResponse.length == 0
            "
            class="flex flex-col gap-2"
          >
            <ReusableUISearchDropdown
              full-width="true"
              label="Select Language"
              :options="formatSelectData(getLanguages)"
              :value="language"
              v-model="language"
            />
            <ReusableUIButtonsButtonPrimary
              @click.stop="
                (AiTool.aiResponse = []),
                  handleAI(
                    `please translate this text in ${language}: ${modelValue}`
                  )
              "
              :loading="loadingPrompt"
              size="medium"
              text="Translate"
              class="ml-auto w-[100px]"
            />
          </div>
          <div class="relative" v-if="selectedOption !== 'Translate'">
            <div class="flex flex-col gap-3">
              <p
                v-if="!modelValue"
                class="font-semibold text-sm/none text-neutral-800 dark:text-white cursor-default"
              >
                {{ AskQuestion }}
              </p>
              <input
                type="text"
                @change="(val) => (promptValue = val.target.value)"
                :value="promptValue"
                placeholder="Enter your prompt"
                class="outline-none text-sm text-neutral-600 placeholder:text-neutral-400 bg-white border border-neutral-300 dark:border-neutral-700 dark:hover:border-neutral-600 bg-transparent hover:border-neutral-400 w-full dark:bg-neutral-800 dark:text-neutral-400 px-4 py-3 focus:border-blue-600 dark:focus:border-blue-400 rounded-md"
              />
            </div>
            <div
              v-if="loadingPrompt"
              class="absolute w-8 h-7 rounded-md bg-blue-600 hover:bg-blue-700 dark:bg-blue-400 dark:hover:bg-blue-500 transition-all ease-out duration-200 cursor-pointer right-3 bottom-2.5 flex justify-center items-center"
            >
              <svg
                aria-hidden="true"
                class="inline w-3.5 h-3.5 text-gray-200 animate dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
            <div
              v-else
              class="absolute w-8 h-7 rounded-md bg-blue-600 hover:bg-blue-700 dark:bg-blue-400 dark:hover:bg-blue-500 transition-all ease-out duration-200 cursor-pointer right-3 bottom-2.5 flex justify-center items-center"
              @click.stop="handleAI()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-4 h-4 text-white"
              >
                <path
                  d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- @keyup="$emit('keyUp', $event.target.value)" -->

    <div v-else class="relative">
      <input
        :type="passwordVisibility"
        :placeholder="placeHolder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        class="outline-none text-sm text-neutral-600 placeholder:text-neutral-400 bg-white border border-neutral-300 bg-transparent w-full transition-all ease-in-out duration-150 hover:border-neutral-400"
        :class="[
          moreClass ? moreClass : ' px-4 py-3',
          darkInput
            ? ''
            : 'dark:placeholder:text-neutral-400 dark:bg-neutral-800 dark:text-neutral-400 dark:border-neutral-700',
          borderRadius ? borderRadius : ' rounded-md',
          disabled ? 'focus:border-neutral-400' : 'focus:border-blue-600',
        ]"
        autocomplete="off"
        :disabled="disabled"
        :id="id"
        :name="name"
      />
      <svg
        v-if="passwordVisibility === 'password'"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="absolute top-[13px] right-4 w-5 h-5 text-neutral-800 dark:text-neutral-200 cursor-pointer"
        @click="changePasswordVisibility()"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
      <svg
        v-else
        @click="changePasswordVisibility()"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="absolute top-[13px] right-4 w-5 h-5 text-neutral-800 dark:text-neutral-200 cursor-pointer"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
        />
      </svg>
    </div>

    <!-- {{formatSelectData(getLanguages)[language]?.title }} -->
    <!-- Character Limit display -->
    <div
      class="relative text-neutral-800 dark:text-neutral-200"
      v-if="characterLimit > 0"
    >
      <p
        class="absolute bottom-[26px] right-4 opacity-50 text-xs"
        v-if="characterLimit > 0"
      >
        {{ characters }} / {{ characterLimit }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useCommonStore } from "~~/store/common";
import { useSettingsStore } from "~~/store/settings";
const settingsStore = useSettingsStore();
const { getLanguages, pageSettings } = storeToRefs(settingsStore);
const commonStore = useCommonStore();
const { getTimelineData } = storeToRefs(commonStore);

const props = defineProps({
  customLeading: Boolean,
  label: String,
  type: String,
  error: Boolean,
  placeHolder: String,
  toolTipText: String,
  modelValue: String,
  fullWidth: String,
  borderRadius: String,
  minValue: Number,
  width: String,
  disabled: {
    type: Boolean,
    default: false,
  },
  id: String,
  moreClass: String,
  noTransition: Boolean,
  dark: {
    type: Boolean,
    default: false,
  },
  truncate: Boolean,
  capitalize: {
    type: Boolean,
    default: false,
  },
  labelLeading: String,
  characterLimit: { default: 0, type: Number },
  labelClasses: String,
  customGap: String,
  darkLabel: String,
  darkInput: String,
  name: { default: "", type: String },
  showAssistance: Boolean,
  helpingText: String,
  AskQuestion: String,
  dusk: String,
});
const AiTool = ref({
  menu: false,
  promptModal: false,
  aiResponse: [],
  prompt: [],
  editOPtions: [
    "Edit with AI",
    "Fix spellings and grammar",
    "Expand More",
    "Make simpler",
    "Translate",
  ],
});
const emit = defineEmits(["update:modelValue, keyUp"]);
const promptValue = ref("");
const passwordVisibility = ref("password");
const characters = ref(0);
const loadingPrompt = ref(false);
const language = ref("");
const selectedOption = ref("");
const changePasswordVisibility = () => {
  if (passwordVisibility.value === "password") {
    passwordVisibility.value = "text";
  } else {
    passwordVisibility.value = "password";
  }
};

const handleCharacterLimit = (e) => {
  if (props.characterLimit && props.characterLimit !== -1) {
    characters.value = e.target.value.length;
    if (characters.value > props.characterLimit) {
      e.target.value = e.target.value.slice(0, props.characterLimit);
      characters.value = e.target.value.length;
      emit("update:modelValue", e.target.value);
      emit("keyUp", e.target.value);
    }
  }

  emit("keyUp", e.target.value);
};
const handleAI = async (prompt) => {
  loadingPrompt.value = true;
  const payload = {
    integrationID: "6V520hNqrpmUnIn",
    prompt: prompt ? prompt : (props.helpingText += promptValue.value),
    systemPrompt: prompt ? prompt : promptValue.value,
  };
  const response = await call("/complete", "POST", payload);
  if (response) {
    let newMessage = {
      prompt: { type: "human", message: promptValue.value },
      aiResponse: {
        type: "bot",
        message: response.data?.success.replace(/['"]+/g, ""),
      },
    };
    promptValue.value = "";
    loadingPrompt.value = false;

    AiTool.value.aiResponse.push(newMessage);
  }
};
onBeforeMount(() => {
  if (
    props.characterLimit &&
    props.modelValue &&
    props.modelValue?.trim() !== ""
  ) {
    handleCharacterLimit({ target: { value: props.modelValue } });
  }
});

const handleAIEditor = (val) => {
  promptValue.value = props.modelValue;
  AiTool.value.promptModal = true;
  switch (val) {
    case "Fix spellings and grammar":
      AiTool.value.aiResponse = [];
      handleAI(`please fix spellings and grammar in: ${props.modelValue}`);
      break;
    case "Expand More":
      handleAI(`please give further details about: ${props.modelValue}`);
      break;
    case "Make simpler":
      handleAI(`please simplify this text: ${props.modelValue}`);
      break;

    case "Translate":
      AiTool.value.aiResponse = [];
      break;
  }
};

const vOutside = {
  mounted(el, binding, vnode) {
    const onClickOutside = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        AiTool.value.menu = false;
        AiTool.value.promptModal = false;
      }
    };
    setTimeout(() => {
      document.body.addEventListener("click", onClickOutside);
      el._onClickOutside = onClickOutside;
    }, 2000);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el._onClickOutside);
    delete el._onClickOutside;
  },
};

const handlePromptClose = () => {
  AiTool.value.promptModal = false;
  language.value = "english";
};
</script>
